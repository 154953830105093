<template>
    <v-card>
        <v-toolbar flat outlined>
            <v-icon @click="close()">mdi-close</v-icon>

            <v-spacer />

            <v-toolbar-title>Edit task</v-toolbar-title>

            <v-spacer />
            
            <v-btn @click="save()" small text>Save</v-btn>
        </v-toolbar>

        <v-card-text>
            <v-container>
                <v-row>
                    <v-text-field
                        label="Title"
                        v-model="titleLocal"
                        :disabled="!titleIsUserProvided"
                        required
                    ></v-text-field>
                </v-row>
                <v-row>
                    <v-text-field
                        label="Reference"
                        v-model="referenceLocal"
                        :disabled="!referenceIsUserProvided"
                    ></v-text-field>
                </v-row>
                <v-row v-if="showDueDate">
                    <v-text-field
                        label="Due"
                        v-model="dueDateInput"
                        required
                        type="datetime-local"
                        placeholder="dd/mm/yyyy hh:mm"
                    ></v-text-field>
                </v-row>
            </v-container>
        </v-card-text>
    </v-card>
</template>

<script>
import tasksAgent from "./tasksAgent";

export default {
    props: {
        id: String,
        reference: String,
        referenceIsUserProvided: Boolean,
        title: String,
        titleIsUserProvided: Boolean,
        dueDate: String,
        showDueDate: {
            type: Boolean,
            default: true,
        }
    },
    
    data: function () {
        return {
            referenceLocal: this.reference,
            titleLocal: this.title,
            dueDateLocal: this.dueDate,
        };
    },
    computed: {
        dueDateInput: {
            get: function() {
                return this.dueDateLocal
                    ? this.$moment(this.dueDateLocal).format("YYYY-MM-DDTHH:mm")
                    : null;
            },
            set: function(newValue) {
                if (newValue)
                    this.dueDateLocal = this.$moment(newValue).utc().format();
            }
        }
    },
    methods: {
        save () {
            var l = this.$coreUi.loading();
            
            // Load workItem
            tasksAgent.getTask(this.id).then((result) => {

                // Set fields and save
                if (this.referenceIsUserProvided)
                    result.workItem.reference = this.referenceLocal;

                if (this.titleIsUserProvided)
                    result.workItem.title = this.titleLocal;

                if (this.showDueDate && result.aspectData.taskDueDate)
                    result.aspectData.taskDueDate.dueDateTime = this.dueDateLocal;
                
                tasksAgent.saveTask(this.id, result).then(() => {

                    l.dismiss();
                    this.close(true);

                }).catch(console.error);

            }).catch(console.error);
        },

        close (result) {
            this.$emit("close", result);
        }
    },
};
</script>
